export async function fetchInputDataOptions() {
  try {
    const inputDataOptionsResponse = await fetch('api/input_data_options');
    if (inputDataOptionsResponse.status !== 200) throw new Error(`👺 ${inputDataOptionsResponse.status} ${inputDataOptionsResponse.statusText}`);
    const inputDataOptions = await inputDataOptionsResponse.json();
    return inputDataOptions;
  } catch (error) {
    console.error(`👺 Error fetching input data options: ${error}`);
    return [];
  }
}

export function openSVGInNewTab(featureImportanceSVG) {
  const win = window.open();
  win.document.write('<html><head>');
  win.document.write('<meta charset="utf-8">');
  win.document.write('<title>SVG Preview</title></head>');
  win.document.write(`<body>${featureImportanceSVG}</body></html>`);
  win.document.close();
}

function removeSvgSize(svgString) {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = svgDoc.querySelector('svg');

  // Remove width and height attributes
  svgElement.removeAttribute('width');
  svgElement.removeAttribute('height');

  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
}

export async function getTreeFeatureImportanceGraph(
  {
    inputDataSelected,
    maxTreeDepth,
    criterionSelected,
    splitterSelected,
    specificFeaturesSelected,
    importanceSelected,
    randomStateSelected,
    testTrainSplit,
    featureValues = {},
    includeLegend = true,
  },
) {
  try {
    const url = [
      `api/tree/importance/${inputDataSelected}/?export_type=SVG`,
      `&max_depth=${maxTreeDepth}`,
      `&criterion=${criterionSelected}`,
      `&splitter=${splitterSelected}`,
      `&importance=${importanceSelected}`,
      `&random_state=${randomStateSelected}`,
      `&test_train_split=${testTrainSplit}`,
      `&include_legend=${includeLegend}`,
    ].join('');

    const featureImportanceResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        specific_features: specificFeaturesSelected,
        featureValues,
      }),
    });
    if (featureImportanceResponse.status !== 200) {
      throw new Error(
        `👺 ${featureImportanceResponse.status} ${featureImportanceResponse.statusText}`,
      );
    }
    const featureImportanceArray = await featureImportanceResponse.json();
    // iterate over each feature importance graph and remove width and height attributes
    const cleanedFeatureImportanceArray = featureImportanceArray.map(
      (featureImportanceSVG) => removeSvgSize(featureImportanceSVG),
    );
    return cleanedFeatureImportanceArray;
  } catch (error) {
    console.error(`👺 Error fetching feature importance graph: ${error}`);
    return '';
  }
}

export async function getForestFeatureImportanceGraph(
  {
    inputDataSelected,
    maxTreeDepth,
    specificFeaturesSelected,
    importanceSelected,
    randomStateSelected,
    objectiveSelected = 'multi:softprob',
    learningRate = 1.0,
    boostingRounds = 100,
    testTrainSplit,
    featureValues = {},
    includeLegend = true,
  },
) {
  try {
    const url = [
      `/api/forest/importance/${inputDataSelected}/?max_depth=${maxTreeDepth}`,
      `&random_state=${randomStateSelected}`,
      `&learning_rate=${learningRate}`,
      `&objective=${objectiveSelected}`,
      `&importance=${importanceSelected}`,
      `&boosting_rounds=${boostingRounds}`,
      `&test_train_split=${testTrainSplit}`,
      `&include_legend=${includeLegend}`,
    ].join('');

    const featureImportanceResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          featureValues,
          specific_features: specificFeaturesSelected,
        },
      ),
    });
    if (featureImportanceResponse.status !== 200) {
      throw new Error(
        `👺 ${featureImportanceResponse.status} ${featureImportanceResponse.statusText}`,
      );
    }
    const featureImportanceArray = await featureImportanceResponse.json();
    // iterate over each feature importance graph and remove width and height attributes
    const cleanedFeatureImportanceArray = featureImportanceArray.map(
      (featureImportanceSVG) => removeSvgSize(featureImportanceSVG),
    );
    return cleanedFeatureImportanceArray;
  } catch (error) {
    console.error(`👺 Error fetching feature importance graph: ${error}`);
    return '';
  }
}

export async function getForestStats(
  {
    inputDataSelected,
    maxTreeDepth,
    specificFeaturesSelected,
    importanceSelected,
    randomStateSelected,
    testTrainSplit,
    objectiveSelected = 'multi:softprob',
    learningRate = 1.0,
    boostingRounds = 100,
  },
) {
  try {
    const url = [
      `/api/forest/stats/${inputDataSelected}/?max_depth=${maxTreeDepth}`,
      `&random_state=${randomStateSelected}`,
      `&learning_rate=${learningRate}`,
      `&objective=${objectiveSelected}`,
      `&importance=${importanceSelected}`,
      `&test_train_split=${testTrainSplit}`,
      `&boosting_rounds=${boostingRounds}`,
    ].join('');

    const forestStatsResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          specific_features: specificFeaturesSelected,
        },
      ),
    });

    if (forestStatsResponse.status !== 200) {
      throw new Error(
        `👺 ${forestStatsResponse.status} ${forestStatsResponse.statusText}`,
      );
    }
    const forestStats = await forestStatsResponse.json();
    return forestStats;
  } catch (error) {
    console.error(`👺 Error fetching feature stats: ${error}`);
    return null;
  }
}

function getTargetOrdinalMapping({ targetIndex, targetSelected, metaDataSelected }) {
  return metaDataSelected?.ordinal_mapping[targetSelected][targetIndex];
}

export async function generateForestPrediction({
  selectedFeatureValues, forestConfiguration,
  metaDataSelected, targetSelected, importanceSelected,
}) {
  const inputSample = Object.entries(
    selectedFeatureValues,
  ).map(([featureName, featureValue]) => {
    // if the feature is ordinal, look up the feature value
    if (metaDataSelected.type[featureName] === 'ordinal') {
      return metaDataSelected.ordinal_mapping[featureName].indexOf(featureValue);
    }
    return featureValue;
  });
  console.debug('🧠 inputSample', inputSample);

  try {
    const url = [
      `/api/predict_forest/${forestConfiguration.inputDataSelected}/?max_depth=${forestConfiguration.maxTreeDepth}`,
      `&random_state=${forestConfiguration.randomStateSelected}`,
      `&learning_rate=${forestConfiguration.learningRate}`,
      `&objective=${forestConfiguration.objectiveSelected}`,
      `&importance=${importanceSelected}`,
      `&test_train_split=${forestConfiguration.testTrainSplit}`,
      `&boosting_rounds=${forestConfiguration.boostingRounds}`,
    ].join('');

    const predictionResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          input_sample: inputSample,
          specific_features: forestConfiguration.specificFeaturesSelected,
        },
      ),
    });
    if (predictionResponse.status === 200) {
      const predictionResult = await predictionResponse.json();
      const internalPrediction = predictionResult[0];
      // for the prediction, look at the target values and create an
      // object of {target, probability} pairs
      // need to look up target name too in
      const predictionResultArray = Object.entries(internalPrediction)
        .map(([targetIndex, probability]) => ({
          target: `${targetSelected} ${getTargetOrdinalMapping({ targetIndex, targetSelected, metaDataSelected })}`,
          probability,
        }));
      console.debug('🔮 predictionResultArray', predictionResultArray);
      return predictionResultArray;
    }
  } catch (error) {
    console.error('🛑 Error fetching prediction: ', error);
  }
  return null;
}
