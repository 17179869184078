<template>
  <div class="container-responsive">
    <div class="row">
      <LoadingSpinner v-if="loading" />
      <div class="col-lg-10">
        <div ref="cytoContainer">
          <div ref="cyto" style="border: solid; border-color: lightgrey; height:100%" />
        </div>
      </div>
      <div class="col-md-2">
        <h6 class="row">Decision Tree Controls</h6>
        <decision-tree-controls
          @controlsUpdated="updateTreeConfiguration" />
        <div class="row mt-2">
          <PredictionPanel
            :features="featuresSelected"
            :metaData="metaDataSelected"
            @clearedPrediction="clearPredictionPath"
            @predictionToBeGenerated="predictionPressed" />
        </div>
        <div v-if="forceGraphs.length || waterfallGraphs.length" class="row btn-group btn-group-sm btn-group-vertical mt-2" role="group">
          <button
            type="button"
            class="btn btn-secondary interaction-button"
            v-for="(target, index) in targetLabels"
            :class="{ active: selectedPredictionIndex === index }"
            :key="target"
            :id="target"
            @click="changePredictionSelection">
            {{ target }}
          </button>
        </div>
        <div
          v-if="forceGraphSVG"
          class="row short-svg-container forceGraphSVG row mt-2 mb-2"
          @click="openSVG(forceGraphSVG)"
          @keypress="openSVG(forceGraphSVG)">
          <div v-html="forceGraphSVG" />
        </div>
        <div
          v-if="waterfallGraphSVG"
          class="row svg-container waterfallGraphSVG row mt-2 mb-2"
          @click="openSVG(waterfallGraphSVG)"
          @keypress="openSVG(waterfallGraphSVG)">
          <div v-html="waterfallGraphSVG" />
        </div>
        <div class="row mt-1">
          <div class="form-group col">
            <label for="importanceSelector" class="row form-label smaller-text">Importance Graph:</label>
            <select
              class="row form-select form-select-sm"
              id="importanceSelector"
              v-model="importanceSelected"
              @change="updateImportanceSVG">
              <option value="shapley_summary">Shapley Summary</option>
              <option v-if="numberOfTargets === 1" value="shapley_beeswarm">Shapley Beeswarm</option>
              <option v-if="numberOfTargets === 1" value="shapley_scatter">Shapley Scatter</option>
              <option value="gini">Gini</option>
            </select>
            <div class="form-check mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="includeLegend"
                v-model="includeLegend"
                @change="includeLegendChanged">
              <label class="smaller-text" for="includeLegend">
                Include Legend
              </label>
            </div>
            <div
              v-if="featureImportanceSVG"
              :class="importanceSelected === 'gini' ? 'long-svg-container' : 'svg-container'"
              class="row summaryGraphSVG mt-3"
              @click="openSVG(featureImportanceSVG)"
              @keypress="openSVG(featureImportanceSVG)">
              <div v-html="featureImportanceSVG" />
            </div>
          </div>
        </div>
        <div class="row">
          <h6 class="row smaller-text">Decision Tree Statistics</h6>
          <div v-if="treeStats" class="smaller-text">
            <p class="row">Test Accuracy: {{ (treeStats.test_accuracy * 100).toFixed(1) }}%</p>
            <p class="row">Training Accuracy: {{ (treeStats.training_accuracy * 100).toFixed(1) }}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.svg-container {
  width: 240px;
  height: 95px;
  overflow: auto;
}
.long-svg-container {
  width: 240px;
  height: 180px;
  overflow: auto;
}
.short-svg-container {
  width: 240px;
  height: 30px;
  overflow: auto;
}

.prediction-path {
  background-color: red !important;
  line-color: red !important;
  target-arrow-color: red !important;
}

.btn-clear {
  border: none;
  background: transparent;
  color: rgba(0, 0, 0, .5);
  cursor: pointer;
  margin-left: 10px;
}

.btn-clear:hover {
  color: rgba(0, 0, 0, .8);
}

.smaller-text {
  font-size: 0.7rem;
}

.interaction-button {
  width: 100%;
  white-space: nowrap;
}
</style>

<script>
import 'bootstrap/scss/bootstrap.scss';
import 'tippy.js/dist/tippy.css';
import DecisionTreeControls from './DecisionTreeControls.vue';
import { openSVGInNewTab, getTreeFeatureImportanceGraph } from './lib/utils';
import PredictionPanel from './PredictionPanel.vue';
import LoadingSpinner from './LoadingSpinner.vue';

import {
  initializeCyto, addToolTips, showPredictedPath,
} from './lib/cytoscapeManager';

export default {
  components: {
    DecisionTreeControls,
    PredictionPanel,
    LoadingSpinner,
  },
  data() {
    return {
      cyto: null,
      treeConfiguration: {},
      importanceSelected: 'shapley_summary',
      targetMapping: [],
      treeStats: null,
      featureImportanceSVG: null,
      selectedFeatureValues: {},
      cytoscapeInitialized: false,
      selectedPredictionIndex: 0,
      forceGraphs: [],
      waterfallGraphs: [],
      loading: false,
      includeLegend: true,
    };
  },
  mounted() {
    this.resizeCyto();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeCyto);
  },
  computed: {
    randomStateComputed() {
      return this.treeConfiguration.randomStateSelected
        ? this.treeConfiguration.randomStateSelected : null;
    },
    targetLabels() {
      if (this.targetMapping.length === 0) return [];
      return Object.values(this.targetMapping[0]);
    },
    forceGraphSVG() {
      if (this.forceGraphs
        && this.selectedPredictionIndex < this.forceGraphs.length) {
        return this.forceGraphs[this.selectedPredictionIndex];
      }
      return null;
    },
    waterfallGraphSVG() {
      if (this.waterfallGraphs
        && this.selectedPredictionIndex < this.waterfallGraphs.length) {
        return this.waterfallGraphs[this.selectedPredictionIndex];
      }
      return null;
    },
    metaDataSelected() {
      // eslint-disable-next-line vue/max-len
      return this.treeConfiguration?.inputDataOptions?.find((inputDataOption) => inputDataOption.filename === this.treeConfiguration.inputDataSelected)?.metadata;
    },
    featuresSelected() {
      if (!this.treeConfiguration?.specificFeaturesSelected) return [];
      const featuresInUse = Object.entries(this.treeConfiguration.specificFeaturesSelected)
        .filter(([, value]) => value === 'feature')
        .map(([key]) => key);
      return featuresInUse;
    },
    numberOfTargets() {
      if (!this.treeConfiguration?.specificFeaturesSelected) return 0;
      const targetCount = Object.values(this.treeConfiguration.specificFeaturesSelected)
        .filter((value) => value === 'target').length;
      return targetCount;
    },
    validConfiguration() {
      return this.featuresSelected.length && this.numberOfTargets;
    },
  },
  methods: {
    changePredictionSelection(event) {
      this.selectedPredictionIndex = this.targetLabels.indexOf(event.target.id);
    },
    openSVG(featureImportanceSVG) {
      openSVGInNewTab(featureImportanceSVG);
    },
    resizeCyto() {
      if (this.$refs.cyto) {
        const newHeight = `${window.innerHeight - 100}px`;
        this.$refs.cyto.style['min-height'] = newHeight;
        this.$refs.cyto.style.height = newHeight;
      }
      if (this.cyto) {
        this.cyto.resize();
        this.cyto.fit();
        this.runCytoLayout();
      }
    },
    clearPredictionPath() {
      // Resetting all elements to their default style
      this.cyto.batch(() => {
        this.cyto.elements().style('background-color', 'blue');
      });
      this.forceGraphs = [];
      this.waterfallGraphs = [];
      this.selectedPredictionIndex = 0;
    },
    predictionPressed(incomingSelectedFeatureValues) {
      if (incomingSelectedFeatureValues) {
        this.selectedFeatureValues = incomingSelectedFeatureValues;
      }
      this.generatePredictionPath(this.selectedFeatureValues);
    },
    includeLegendChanged() {
      this.generatePredictionPath();
      this.updateImportanceSVG();
    },
    async generatePredictionPath() {
      if (!this.validConfiguration || Object.keys(this.selectedFeatureValues).length === 0) return;

      this.loading = true;
      this.forceGraphs = [];
      this.waterfallGraphs = [];
      this.selectedPredictionIndex = 0;
      showPredictedPath(this.selectedFeatureValues, this.metaDataSelected, this.cyto);

      // first ensure we only have one target
      if (this.numberOfTargets === 1) {
        const optionsForFeatureImportanceGraph = {
          ...this.treeConfiguration,
          importanceSelected: this.importanceSelected,
          featureValues: this.selectedFeatureValues,
          includeLegend: this.includeLegend,
        };

        this.forceGraphs = await getTreeFeatureImportanceGraph({
          ...optionsForFeatureImportanceGraph,
          importanceSelected: 'shapley_force',
        });

        this.waterfallGraphs = await getTreeFeatureImportanceGraph({
          ...optionsForFeatureImportanceGraph,
          importanceSelected: 'shapley_waterfall',
        });
      }
      this.loading = false;
    },
    runCytoLayout() {
      this.cyto.layout({
        name: this.treeConfiguration.selectedLayout, animate: true, fit: true, animationDuration: 300, animationEasing: 'ease-in',
      }).run();
    },
    async updateImportanceSVG() {
      this.loading = true;
      if (!this.validConfiguration) return;
      this.featureImportanceSVG = await getTreeFeatureImportanceGraph({
        ...this.treeConfiguration,
        importanceSelected: this.importanceSelected,
        includeLegend: this.includeLegend,
      });
      this.loading = false;
    },
    async updateTreeConfiguration(incomingTreeConfiguration) {
      this.loading = true;
      this.forceGraphs = [];
      this.waterfallGraphs = [];
      this.importanceSelected = 'shapley_summary';
      this.treeConfiguration = incomingTreeConfiguration;
      if (!this.cytoscapeInitialized) {
        // eslint-disable-next-line vue/max-len
        this.cyto = initializeCyto(this.$refs.cyto, this.treeConfiguration.selectedLayout, this.resizeCyto);
      }
      this.cyto.elements().remove();
      if (!this.validConfiguration) return;

      try {
        const url = [
          `api/tree/${this.treeConfiguration.inputDataSelected}/?export_type=JSON`,
          `&max_depth=${this.treeConfiguration.maxTreeDepth}`,
          `&criterion=${this.treeConfiguration.criterionSelected}`,
          `&splitter=${this.treeConfiguration.splitterSelected}`,
          `&test_train_split=${this.treeConfiguration.testTrainSplit}`,
          `&random_state=${this.randomStateComputed}`,
        ].join('');

        const decisionTreeGraphResponse = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              specific_features: this.treeConfiguration.specificFeaturesSelected,
            },
          ),
        });
        if (decisionTreeGraphResponse.status !== 200) throw new Error(`👺 ${decisionTreeGraphResponse.status} ${decisionTreeGraphResponse.statusText}`);
        const decisionTreeRawResponse = await decisionTreeGraphResponse.text();
        console.debug('🌳 Decision tree raw response: ', decisionTreeRawResponse);
        // now parse json
        const decisionTreeGraph = JSON.parse(decisionTreeRawResponse);
        console.debug('🌳 Decision tree graph: ', decisionTreeGraph);
        this.cyto.add(decisionTreeGraph.elements);
        this.treeStats = decisionTreeGraph.tree_stats;
        this.targetMapping = decisionTreeGraph.target_mapping;
        addToolTips(this.cyto.elements());
        this.runCytoLayout();
        this.updateImportanceSVG();
      } catch (error) {
        console.error(`👺 Error rendering decision tree: ${error}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
